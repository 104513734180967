import { Table } from "antd";
import { useState, useEffect } from "react";
import moment from "moment";

const momenttz = require("moment-timezone");

function YearTable() {
  const currentYear = moment().tz("Asia/Kolkata").year();
  const [tableColumn, setTableColumn] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [parsedValue, setParsedValue] = useState(null);

  const isYearTable = window.location.search.includes("?");

  const getYearDatabtn = () => {
    const params = new URLSearchParams(window.location.search);
    const value = params.get("value");
    if (value) {
      const [gameName, year] = value.split("-");
      const getYearData = {
        year: year,
        gameName: gameName,
      };
      return getYearData;
    }
    return null;
  };

  useEffect(() => {
    setParsedValue(getYearDatabtn());
  }, []);

  useEffect(() => {
    const getYearData = getYearDatabtn();
    if (getYearData) {
      fetch("https://api.sattakingvip.co.in/getyeardata", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(getYearData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((json) => {
          // Add serial numbers to each row of the year data
          const yearDataWithSerialNumber = json.map((item, index) => ({
            ...item,
            key: index + 1, // Key/Serial Number
            serialNumber: index + 1, // Add serialNumber field
          }));
          setYearData(yearDataWithSerialNumber);
          //   console.log("json=====>", json);
        })
        .catch((error) => console.error(error));
    }
  }, []);

  useEffect(() => {
    if (yearData.length) {
      const allMonths = Array.from({ length: 12 }, (_, i) =>
        moment().month(i).format("MMM")
      );
      let dayArray = [
        { title: "DAY", dataIndex: "serialNumber", key: "serialNumber" }, // Add serial number column
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];

      for (let i = 1; i < dayArray.length; i++) {
        dayArray[i] = {
          title: allMonths[Number(dayArray[i]) - 1], // Use month names
          dataIndex: dayArray[i],
          key: dayArray[i],
        };
      }
      setTableColumn(dayArray);
    }
  }, [yearData]);

  return (
    <>
      <div className="monthYrTbl mt-4" id="table-section">
        {isYearTable && parsedValue && (
          <>
            <h3 className="text-center bg-dark text-white p-2">
              {`${parsedValue.gameName} YEARLY RECORD CHART - ${parsedValue.year}`}
            </h3>
            <div className="table-responsive">
              <Table
                dataSource={yearData}
                columns={tableColumn}
                pagination={false}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default YearTable;
