import Select from 'react-select';
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import MonthTable from '../monthTable'; // Adjust the import as needed
import YearlyFooterDrop from './yearlyFooterDrop';
import { useLocation } from 'react-router-dom';
import AdvertisementComponent from '../utilities/advertismentComponent';
const momenttz = require('moment-timezone');

let monthFullName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function FooterDropBtn() {
    const [selectedOption, setSelectedOption] = useState(null);
    const [selecYear, setSelectedYear] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [sendList, sndList] = useState([]);
    const [selecteData, sendData] = useState();
    const location = useLocation();
    const isPrivacy = location.pathname.includes('/privacy-policy');
    const isCoockie = location.pathname.includes('/cookie-policy');
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    const curMonth = moment().tz('Asia/Kolkata').format('MMMM');
    const numbermonth = monthFullName.indexOf(curMonth);

    const currYear = moment.tz('Asia/Kolkata').format('YYYY');
    const selectMonthnew = moment().tz('Asia/Kolkata').format("MMMM");
    const [dropValue, getDropValue] = useState([])

    const YearGame = { selecYear, selectedOption, selectMonthnew, selectedMonth };


    useEffect(() => {
        fetch("https://api.sattakingvip.co.in/getGameName", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        })
          .then((response) => response.json())
          .then((json) => sndList(json))
          .catch((error) => console.error(error));
    }, []);

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    const handleChangeYear = (selecYear) => {
        setSelectedYear(selecYear);
    };

    const handleChangeMonth = (selectedMonth) => {
        setSelectedMonth(selectedMonth);
    };

    const customStyles = {

        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#c6007e',  // Background color of the select box
            color: '#fff',               // Font color
            borderColor: state.isFocused ? '#000' : '#ccc',
            minHeight: '46px',
            height: '46px',
            boxShadow: state.isFocused ? null : null,
            '&:hover': {
                borderColor: state.isFocused ? '#000' : '#ccc',
            }
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#b9007c' : '#fff', // Background color of the dropdown options
            color: state.isSelected ? '#fff' : '#333',           // Font color of the dropdown options
            '&:hover': {
                backgroundColor: state.isSelected ? '#b9007cad' : '#b9007cad', // Hover state background color
                color: state.isSelected ? '#fff' : '#fff',           // Hover state font color
            }
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#fff',  // Font color of the selected option
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#fff',  // Background color of the dropdown menu
            zIndex: 100,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '46px',
            padding: '0 8px',
        }),
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '46px',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: '#fff',  // Placeholder font color
        }),



    };

    let monthArray = [];
    for (let i = 0; i < monthFullName.length; i++) {
        monthArray.push({ value: i + 1, label: monthFullName[i] });
    }

    function apiData(params) {
        fetch("https://api.sattakingvip.co.in/getmonthdata", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            month: params.month,
            year: params.year,
            gameName: params.gameName ? params.gameName : "",
            result: "",
            days: params.days,
          }),
        })
          .then((response) => response.json())
          .then((json) => {
            sendData(json);
          })
          .catch((error) => console.error(error));
    }

    function getAllGameDetails() {
        if (selectedMonth != null && selecYear != null && selectedOption != null) {
            let allData = {
                month: selectedMonth ? selectedMonth.value : '',
                year: selecYear ? selecYear.value : '',
                gameName: selectedOption ? selectedOption.value : '',
                result: '',
                days: moment(selecYear.value + "-" + selectedMonth.value).daysInMonth()
            }
            if (selectedMonth.value > currentMonth && currentYear == selecYear.value) {

                // apiData(allData);
                toast.error("Selected month out of Date", {
                    className: "toast-message"
                });

            } else if (selectedMonth != null && selecYear != null && selectedOption != null) {

                apiData(allData);
                getDropValue(allData);
            }
        } else {
            return toast.error("All Fields Required", {
                className: "toast-message"
            });
        }

    }

    const currentYear = new Date().getFullYear();
    let arr = [];
    for (let year = currentYear - 9; year <= currentYear; year++) {
        arr.push({ value: year, label: year });
    }

    return (
        <div className="footer">
             <AdvertisementComponent type='even' />
            {
                (!(isCoockie || isPrivacy)) && (
                    <>
                        <MonthTable gameData={selecteData} dropValue={dropValue} />

                        <div className="yellow-container" id='monthTable'>
                            <div className="bottom-container">
                                <div className="selection-container">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-l-3 col-md-3 col-sm-12 col-xs-12 pb-2">
                                                <Select menuPlacement="auto" value={selectedMonth} onChange={handleChangeMonth} options={monthArray} placeholder="Select Month" styles={customStyles} />
                                            </div>
                                            <div className="col-l-3 col-md-3 col-sm-12 col-xs-12 pb-2">
                                                <Select menuPlacement="auto" value={selecYear} onChange={handleChangeYear} options={arr} placeholder="Select Year" styles={customStyles} />
                                            </div>
                                            <div className="col-l-3 col-md-3 col-sm-12 col-xs-12 pb-2">
                                                <Select menuPlacement="auto" value={selectedOption} onChange={handleChange} options={sendList} placeholder="Select Game" styles={customStyles} />
                                            </div>
                                            <div className="col-l-3 col-md-3 col-sm-12 col-xs-12 pb-2">
                                                <button className="form-control" id="go-button" onClick={getAllGameDetails}>Go</button>
                                                <ToastContainer
                                                    autoClose={2000}
                                                    position="top-right"
                                                    hideProgressBar={false}
                                                    newestOnTop={true}
                                                    closeOnClick
                                                    pauseOnHover
                                                    draggable={false} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <YearlyFooterDrop />
                    </>
                )
            }
        </div>
    );
}

export default FooterDropBtn;
