import logo from './images/logo.png';
import './App.css';
import { useState, useEffect } from 'react';
import DayWise from './js/dayWise';
import moment from 'moment';
import trackVisitor from './utilities/tracker';
import { useLocation } from 'react-router-dom';
import AdvertisementComponent from './utilities/advertismentComponent';
import { Helmet } from 'react-helmet';
const momenttz = require('moment-timezone')
function App() {
  var todayDate = moment().tz('Asia/Kolkata').format("LLL")
  const currentDate = moment().tz('Asia/Kolkata').format('YYYY-MM-DD');
  const prevDate = moment().subtract(1, 'days').tz('Asia/Kolkata').format('YYYY-MM-DD');
  const [dayGameResult, setDayGameResult] = useState([])
  const location = useLocation();
  const isPrivacy = location.pathname.includes('/privacy-policy');
  const isCoockie = location.pathname.includes('/cookie-policy');




  // track all site visited data

  useEffect(() => {
    trackVisitor(); // Call the tracking function when the component mounts
  }, []);


  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        game_name: "",
        curr_date: currentDate,
        prev_date: prevDate,
        open_time: "market_sunday_time_open",
      }),
    })
      .then((Response) => Response.json())
      .then((json) => setDayGameResult(json))
      .catch(console.error());
  }, [])


  function HandleGetResult(event) {
    const value = event.target.value;
    const newUrl = `${window.location.pathname}?value=${value}`;
    window.history.pushState(null, '', newUrl);


    // Reload the page after a short delay to allow for smooth scrolling
    setTimeout(() => {
      window.location.reload();
    }, 1000); // Adjust the delay as needed
    // Scroll to the desired section
    // const tableSection = document.getElementById('table-section');
    // if (tableSection) {
    //   tableSection.scrollIntoView({ behavior: 'smooth' });
    // }

    pageScroll();
  }

  function pageScroll() {
    const tableSection = document.getElementById('monthTable');
    if (tableSection) {
      tableSection.scrollIntoView({ behavior: 'smooth' });
    }
  }

  // function HandleGetResult(event) {
  //   const value = event.target.value;
  //   const newUrl = new URL(window.location.href);

  //   if (value) {
  //     newUrl.searchParams.set('value', value);
  //   } else {
  //     newUrl.searchParams.delete('value');
  //   }

  //   window.history.pushState(null, '', newUrl.toString());
  //   window.location.reload();
  // }

  return (
    <>
      {/* seo setup start */}
      <Helmet>
        <title>SATA COMPANY</title>
        <meta
          name="description"
          content="satta-company,sattasport, satta sport, sattaking"
        />
        <meta
          name="Keywords"
          content="satta-company,satta company, sattakingreal, satta king real, sattaking real, Satta King, Satta King live result, Satta king online result, Satta king online, Satta king result today, Gali result, Desawar result, Faridabad result, Gaziyabad result, Satta matka king, Satta Bazar, Black satta king, Satta king 2017, satta king 2018, Gali Leak Number, Gali Single Jodi, Black Satta Result, Desawar Single Jodi, Satta king up, Satta king desawar, Satta king gali, Satta king 2019 chart, Satta baba king, Satta king chart, Gali live result, Disawar live result, Satta Number, Matka Number, Satta.com, Satta Game, Gali Number, Delhi Satta king,"
        />
        <link rel="canonical" href="https://satta-company.co/" />
      </Helmet>
      {/* seo setup end */}
      {!(isCoockie || isPrivacy) && (
        <>
          <div>
            <div className="col-12 menuBar">
              <div className="row">
                <a href="/" className="col text-center linkText btn">
                  HOME
                </a>
                <a
                  onClick={pageScroll}
                  className="col text-center linkText btn"
                >
                  CHART
                </a>
              </div>

              <div className="text-center imgClass">
                <a href="/">
                  {" "}
                  <img src={logo} className="col" alt="headerimg" />{" "}
                </a>
              </div>

              <div className="row">
                <button
                  className="col text-center linkText btn"
                  value="DISAWAR-2024"
                  onClick={HandleGetResult}
                >
                  DS 2024
                </button>
                <button
                  className="col text-center linkText btn"
                  value="FARIDABAD-2024"
                  onClick={HandleGetResult}
                >
                  FBD 2024
                </button>
                <button
                  className="col text-center linkText btn"
                  value="GAZIABAD-2024"
                  onClick={HandleGetResult}
                >
                  GZB 2024
                </button>
                <button
                  className="col text-center linkText btn"
                  value="GALI-2024"
                  onClick={HandleGetResult}
                >
                  GALI 2024
                </button>
              </div>
            </div>
            <AdvertisementComponent type="odd" />
            <div className="text-center todayday p-3">
              <h5>{todayDate}</h5>
              <DayWise dayGameData={dayGameResult} />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default App;
