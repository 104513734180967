import React from 'react';
import bannerImage from '../images/home.png';
import { useNavigate } from 'react-router-dom';
import AdvertisementComponent from '../utilities/advertismentComponent';

const BannerContent = () => {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };
    return (
        <div className="text-center col-12 banner pt-5 pb-3">
            <AdvertisementComponent type='random' />
            <div className='text-center imgClass p-0 m-0'>
                <a href='/'><img src={bannerImage} className='' alt="Banner" /></a>
            </div>
            <p className="text-center">
                LIVE SATTA KING 2024 Lyrics UPDATE RESULT VIP-XYZ.TIPS 2020-2019-2018-2017 SUPER JODI BLACK SATTA CARE GURU PLAY-BAZAR SATTA FREE VIP GURI JI DATE-FIX SATTA KING MIX CHART 2024 INTERNET ONLINE MUMBAI KALYAN MATKA SHALIMAR BAJAR TIME BAZAR SATTA-WAPKA-MOBI SATTA DELHI RAJASTHAN HARAYANA SOLID GAME SATTA TRICK CHART DOWNLOAD PDF SATTA-NO.CA SATTA Game TIPS TODAY MATKA SINGLE PAKAD HARUF SATTA BADSHAH MAGIC FIX MARKET YOUTUBE ADMIN SIR SATTA HR-FD-SATTA DON 0001 Got You Guessing Like You Playing A Game Of Satta King 2023 KAPIL SATTA MATKA GURU SATTA-MATKA-143 DELHI WORLD MATKA FIX SATTA.ORG MATKA.SITE MOBI ORG IN MATKA CLUB CENTER WAPKA ME WEB SPORT GAMBLING STAR
            </p>
            <span>
                © Copyright SATTA XPRESS All Rights Reserved.
                <span className='footerLink' onClick={() => handleNavigation('/privacy-policy ')}> Privacy </span> |
                <span className='footerLink' onClick={() => handleNavigation('/cookie-policy')}> Cookies Policy </span>
            </span>

        </div>
    );
};

export default BannerContent;
